<template>
  <div class="layout">
    <div id="payment-wrapper" class="" style="position: relative">
      <div id="header" class="header">
        <a id="Header_HomeLogo_A" href="javascrip:void(0)" class="logo_game">
          <img :src="gameData.game_icon" alt="" />
        </a>
        <div class="right-group">
          <div class="right-header">
            <a v-if="!selectServe" @click="toPage()" class="right-header-to">
              Quay về trang chủ
            </a>
            <!-- <a v-if="selectServe && !$isMobile" @click="orderDialog(gateData.ticket_login_url)">
              Lỗi nạp
            </a> -->
            <template v-if="selectServe && !$isMobile">
              <a
                v-for="item in gateData.channel"
                :key="item.name"
                @click="gateDialog(item)"
              >
                {{ item.name }}
              </a>
            </template>
            <a v-if="selectServe && !$isMobile" @click="storeDialog()"
              >Tiệm Điểm</a
            >
            <a v-if="selectServe && !$isMobile" @click="openOrderListDialog()"
              >Đơn hàng</a
            >
            <!-- <a v-if="selectServe && !$isMobile" @click="openVipDialog()">VIP</a> -->
            <a
              v-if="selectServe && gateData.is_refund && !$isMobile"
              @click="unlockDialog()"
            >
              Mở khoá
            </a>
            <a
              v-if="selectServe && !$isMobile && gateData.beta"
              @click="openCodeBindDialog()"
              style="color: red !important"
              >Beta</a
            >
            <a v-if="selectServe && !$isMobile" @click="openVipListDialog()"
              >VIP</a
            >
            <div
              v-if="selectServe && !showServe"
              class="user-name"
              @click="showName(gateData.username)"
            >
              {{ gateData.username }}
            </div>
            <div class="user-info" v-else-if="showServe">
              <div class="user-display">
                <span id="us-userInfo" @click="showName(gateData.username)">{{
                  gateData.username
                }}</span>
                <div id="us-userInfo-uid" class="user-info-uid">ID</div>
              </div>
              <div class="user-info-coin-list">
                <span class="user-info-coin">
                  Coin:
                  <a class="coin-style" @click="openCoinDialog">
                    {{ Number(gateData.coin) + Number(gateData.bind_coin) }}
                  </a>
                </span>
                <span class="user-info-coin">
                  MCoin:
                  <a class="coin-style">
                    {{ Number(gateData.m_coin) }}
                  </a>
                </span>
              </div>
            </div>
            <div>
              <button
                v-if="selectServe"
                type="button"
                class="el-button btnLogout el-button--text"
                @click="removeStatus()"
              >
                (Thoát)
              </button>
            </div>
            <div class="mobile-menu" v-if="$isMobile">
              <button
                class="shape-menu"
                :class="{ active: showMenu }"
                @click="handleClick()"
              >
                <span></span>
                <span></span>
                <span></span>
                <div class="rightPanel-background"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
    <el-menu
      v-show="$isMobile && showMenu"
      class="el-menu-vertical-demo menu"
      :class="{ closemenu: !showMenu }"
    >
      <el-menu-item
        index="Quay về trang chủ"
        @click="toPage()"
        v-if="!selectServe"
      >
        <span> Quay về trang chủ</span>
      </el-menu-item>
      <!-- <el-menu-item index="Lỗi nạp" @click="orderDialog(gateData.ticket_login_url)" v-if="selectServe">
        <span>Lỗi nạp</span>
      </el-menu-item> -->
      <template v-if="selectServe">
        <el-menu-item
          index="item.name"
          @click="gateDialog(item)"
          v-for="item in gateData.channel"
          :key="item.name"
        >
          <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
      <el-menu-item index="Tiệm Điểm" @click="storeDialog()" v-if="selectServe">
        <span>Tiệm Điểm</span>
      </el-menu-item>
      <el-menu-item
        index="Đơn hàng"
        @click="openOrderListDialog()"
        v-if="selectServe"
      >
        <span>Đơn hàng</span>
      </el-menu-item>
      <!-- <el-menu-item
        index="VIP"
        @click="openVipDialog()"
        v-if="selectServe"
      >
        <span>VIP</span>
      </el-menu-item> -->
      <el-menu-item
        index="Mở khoá"
        @click="unlockDialog()"
        v-if="selectServe && gateData.is_refund"
      >
        <span>Mở khoá</span>
      </el-menu-item>
      <el-menu-item
        index="Beta"
        @click="openCodeBindDialog()"
        v-if="selectServe && gateData.beta"
      >
        <span style="color: red">Beta</span>
      </el-menu-item>
      <el-menu-item index="VIP" @click="openVipListDialog()" v-if="selectServe">
        <span>VIP</span>
      </el-menu-item>
    </el-menu>
    <Service
      v-if="selectServe && getUrl"
      :showAdvert="showAdvert"
      :url="gateData.ticket_login_url"
    />
    <Loading1 :show="showLoading" :backgroundColor="false" />
    <Gate ref="Gate" />
    <Unlock ref="Unlock" />
    <Store ref="store" />
    <GiftCode ref="giftCode" />
    <More ref="more" />
    <Coin ref="coin" />
    <OrderList ref="orderList" />
    <Vip ref="vip" />
    <CodeBind ref="CodeBind" />
    <vipList ref="vipList" />
    <Footer />
  </div>
</template>

<script>
import Loading1 from "../../components/Loading1";
import Footer from "../../components/Footer";
import Service from "../../components/Service";
import Gate from "../../components/Gate";
import Unlock from "./components/Unlock.vue";
import Store from "./components/store.vue";
import GiftCode from "../../components/giftCode.vue";
import More from "../../components/more";
import Coin from "./components/coin.vue";
import OrderList from "./components/orderList.vue";
import Vip from "../../components/Vip";
import CodeBind from "../../components/CodeBind";
import VipList from "../../components/VipList/index.vue";
export default {
  name: "",
  mixins: [],
  components: {
    Footer,
    Service,
    Loading1,
    Gate,
    Unlock,
    Store,
    GiftCode,
    More,
    Coin,
    OrderList,
    Vip,
    CodeBind,
    VipList,
  },
  props: {},
  data() {
    return {
      showLoading: false, //loading状态
      // selectServe:false,//选择区服
      // showServe:false,//展示区服
      showMenu: false, //打开菜单
      showAdvert: false,
      // gateData: {}, //gate相关数据
      // gameData: {}, //游戏点击进来的数据
    };
  },
  computed: {
    selectServe() {
      return this.$store.state.selectServe;
    },
    showServe() {
      return this.$store.state.showServe;
    },
    gameData() {
      return this.$store.state.gameData;
    },
    getUrl() {
      let url = window.location.host;
      return url.includes("mgol.vn");
    },
    // gateData() {
    //   return this.$store.state.gameData;
    // },
    gateData: {
      get: function () {
        return this.$store.state.gameData;
      },
      set: function (newData) {
        return newData;
      },
    },
  },
  watch: {
    gateData(newValue, oldValue) {
      if (newValue && this.gateData.beta && !this.gateData.beta_success) {
        this.openCodeBindDialog();
      }
    },
  },
  created() {},
  mounted() {
    // this.gateData = this.$store.state.gameData
    let closure = this.$route.query.closure;
    if (closure) {
      setTimeout(() => {
        this.unlockDialog();
      }, 100);
    }
  },
  methods: {
    //打开充值弹窗
    gateDialog(item) {
      if (item.name === "Nạp Coin") {
        this.$refs.Gate.dialogFormVisible = true;
      } else {
        let params = {};
        this.$http.post("/pay/atmCardPay", params).then((res) => {
          if (res.data.code === 200) {
            window.location.href = res.data.data.redirect_url;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    //打开解封弹窗
    unlockDialog() {
      this.$refs.Unlock.getData();
      this.$refs.Unlock.dialogFormVisible = true;
    },
    //打开商城弹窗
    storeDialog() {
      this.$refs.store.loading = true;
      this.$refs.store.getSelectData();
      this.$refs.store.dialogFormVisible = true;
    },
    //打开礼包码弹窗
    openGiftCodeDialog() {
      if (this.goodsInfo.name) {
        return;
      }
      this.$refs.giftCode.dialogFormVisible = true;
    },
    //打开订单弹窗
    openOrderListDialog() {
      this.$refs.orderList.dialogFormVisible = true;
      this.$refs.orderList.getData();
    },
    //打开支付额外金额获取流水弹窗
    openMoreDialog(goodsInfo) {
      this.$refs.more.dialogFormVisible = true;
      this.$refs.more.goodsInfo = goodsInfo;
    },
    // 打开coin弹窗
    openCoinDialog() {
      this.$refs.coin.dialogFormVisible = true;
      this.$refs.coin.coinInfo = this.gateData;
    },
    // 打开vip弹窗
    openVipDialog() {
      this.$refs.vip.dialogFormVisible = true;
      this.$refs.vip.vipInfo = this.gateData;
    },
    //打开激活码绑定弹框
    openCodeBindDialog() {
      this.$refs.CodeBind.dialogFormVisible = true;
      this.$refs.CodeBind.CodeBindInfo = this.gateData;
    },
    openVipListDialog() {
      this.getVIPInfo();
    },
    orderDialog(url) {
      window.open(url, "_blank");
    },
    //获取VIP信息
    getVIPInfo() {
      let params = {};
      this.$http.post("/center/vipInfo", params).then((res) => {
        if (res.data.code === 200) {
          let { exp, level, add_time } = res.data.data;
          this.$refs.vipList.dialogFormVisible = true;
          this.$refs.vipList.vipInfo = this.gateData;
          this.$refs.vipList.getSwiper();
          this.$refs.vipList.exp = exp;
          this.$refs.vipList.level = level;
          this.$refs.vipList.level2 = level;
          this.$refs.vipList.add_time = add_time;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //是否打开菜单
    handleClick() {
      this.showMenu = !this.showMenu;
    },
    //移除所有登入状态
    removeStatus() {
      this.showLoading = false;
      localStorage.removeItem("token");
      this.$store.state.selectServe = false;
      this.$store.state.showServe = false;
      this.$router.push({
        path: "/login",
        query: {
          g: this.$store.state.gameData.game_id,
        },
      });
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
    },
    //跳转页面
    toPage() {
      this.$router.push("/");
    },
    //展示名字
    showName(name) {
      this.$alert(`<div> ${name}</div>`, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: "Hủy",
      });
    },
    //获取gate信息
    getGateData() {
      let params = {};
      this.$http.get("/center/detail", { params }).then((res) => {
        if (res.data.code === 200) {
          this.$store.state.gameData = res.data.data;
          this.gateData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
$color-theme: rgb(17, 136, 255);
$color-theme-rgb: 17, 136, 255;
@import "./css/pc.scss";
@import "./css/phone.scss";
/deep/ .el-form-item__content {
  margin-left: 0 !important;
}
/deep/ .el-popper {
  left: 0px !important;
}
/deep/ .popper__arrow {
  opacity: 0 !important;
}
</style>
